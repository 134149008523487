import { createSlice } from "@reduxjs/toolkit";
import sidebarConfig from "../../config/sidebarConfig";

const initialState = {
  selectedType: "member",
  expandedItems: Array(sidebarConfig.length).fill(true),
  selectedList: "",
  selectedItem: "",
  selectedSubItem: "",
};

const typeSlice = createSlice({
  name: "type",
  initialState,
  reducers: {
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
      state.expandedItems = Array(sidebarConfig.length).fill(true);
      state.selectedList = "";
      state.selectedItem = "";
      state.selectedSubItem = "";
    },
    toggleExpandedItem: (state, action) => {
      const index = action.payload;
      state.expandedItems[index] = !state.expandedItems[index];
    },
    setSelectedList: (state, action) => {
      state.selectedList = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setSelectedSubItem: (state, action) => {
      state.selectedSubItem = action.payload;
    },
  },
});

export const {
  setSelectedType,
  toggleExpandedItem,
  setSelectedList,
  setSelectedItem,
  setSelectedSubItem,
} = typeSlice.actions;

export default typeSlice.reducer;
