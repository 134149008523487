import { createSlice } from "@reduxjs/toolkit";

const agritekSlice = createSlice({
  name: "agritek",
  initialState: {
    coefficient: "0",
  },
  reducers: {
    updateCoefficient: (state, action) => {
      state.coefficient = action.payload;
    },
  },
});

export const { updateCoefficient } = agritekSlice.actions;
export default agritekSlice.reducer;
