// modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    activeModal: "",
    modalTitle: "",
    modalText: "",
    modalType: "",
    modalStatus: "",
    modalReload: true,
    modalCancel: false,
    modalData: {},
    apiResponse: null,
  },
  reducers: {
    openModal: (state, action) => {
      const {
        modalType,
        modalTitle,
        modalStatus,
        modalText,
        modalData,
        modalCancel,
        modalReload,
        apiResponse,
      } = action.payload;
      state.isOpen = true;
      state.activeModal = modalType;
      state.modalTitle = modalTitle || "";
      state.modalText = modalText || "";
      state.modalCancel = modalCancel || false;
      state.modalData = modalData || {};
      state.modalStatus = modalStatus || "";
      state.modalReload = modalReload === false ? false : true;
      state.apiResponse = apiResponse || null;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.activeModal = "";
      state.modalTitle = "";
      state.modalText = "";
      state.modalType = "";
      state.modalStatus = "";
      state.modalData = {};
      state.apiResponse = null;
      state.modalReload = true;
    },
    writeModalData: (state, action) => {
      const { modalData } = action.payload;
      state.modalData = modalData;
    },
  },
});

export const { openModal, closeModal, writeModalData } = modalSlice.actions;
export default modalSlice.reducer;
