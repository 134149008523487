import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import typeReducer from "../features/typeSlice";
import modalReducer from "../features/modalSlice";
import agritekReducer from "../features/agritekSlice";
import financeReducer from "../features/financeSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    type: typeReducer,
    modal: modalReducer,
    agritek: agritekReducer,
    finance: financeReducer,
  },
});

export default store;
