import { createSlice } from "@reduxjs/toolkit";

const financeSlice = createSlice({
  name: "finance",
  initialState: {
    NegotiateState: false,
  },
  reducers: {
    toggleNegotiateTrue: (state, action) => {
      state.NegotiateState = true;
    },
    toggleNegotiateFalse: (state, action) => {
      state.NegotiateState = false;
    },
  },
});

export const { toggleNegotiateTrue, toggleNegotiateFalse } =
  financeSlice.actions;
export default financeSlice.reducer;
