import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import "react-loading-skeleton/dist/skeleton.css";

const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div></div>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/:type/:listUrl/:itemUrl/:subItemUrl?"
            element={
              <Suspense fallback={<div></div>}>
                <Home />
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
