import add_home from "../assets/img/svg/add_home.svg";
import manage_accounts from "../assets/img/svg/manage_accounts.svg";
import location_away from "../assets/img/svg/location_away.svg";
import share from "../assets/img/svg/share.svg";
import admin_panel_settings from "../assets/img/svg/admin_panel_settings.svg";
import dashboard_customize from "../assets/img/svg/dashboard_customize.svg";
import savings from "../assets/img/svg/savings.svg";

const sidebarConfig = [
  {
    type: "member",
    list: {
      configuration: {
        logo: manage_accounts,
        title: "會員設定",
        url: "configuration",
        items: {
          list: { url: "list", title: "會員列表" },
          levels: { url: "levels", title: "會員等級權益設定" },
          consumption: { url: "consumption", title: "會員消費足跡" },
        },
      },
    },
  },
  {
    type: "hotel",
    list: {
      setting: {
        logo: add_home,
        title: "館別房型設定",
        url: "setting",
        items: {
          property: {
            url: "property",
            title: "館別設定",
            describe: "管理館別並讓用戶搜尋時指定的名稱。",
            subItem: {
              new: {
                url: "new",
                title: "新增館別",
                describe: "新增館別內容",
              },
              edit: {
                url: "edit",
                title: "編輯館別 - ",
              },
              view: {
                url: "view",
                title: "預覽館別 - ",
              },
            },
          },
          room: {
            url: "room",
            title: "房型列表",
            describe: "管理所有房型資訊。",
            subItem: {
              new: {
                url: "new",
                title: "新增房型",
                describe: "",
              },
              edit: {
                url: "edit",
                title: "編輯房型",
              },
              view: {
                url: "view",
                title: "預覽房型 - ",
              },
            },
          },
          content: { url: "content", title: "方案內容設定" },
          discount: {
            url: "discount",
            title: "折扣設定",
          },
          tags: {
            url: "tags",
            title: "標籤設定",
            describe: "管理所有自訂標籤設定。",
          },
        },
      },
      pms: {
        logo: location_away,
        title: "PMS 控房管理",
        url: "pms",
        items: {
          price: { url: "price", title: "價格總表" },
          plan: { url: "plan", title: "房型方案管理" },
          daily: { url: "daily", title: "每日房型庫存" },
          onsite: { url: "onsite", title: "現場房況" },
          order: { url: "order", title: "訂單列表" },
        },
      },
      ota: {
        logo: share,
        title: "OTA 基本設定",
        url: "ota",
        items: {
          connection: { url: "connection", title: "OTA 通路串接" },
          channel: { url: "channel", title: "通路對應" },
          codes: { url: "codes", title: "PMS 代碼對應" },
        },
      },
      accounts: {
        logo: manage_accounts,
        title: "會員設定",
        url: "accounts",
        items: {
          list: { url: "list", title: "會員列表" },
          level: { url: "level", title: "會員分級設定" },
        },
      },
      dashboard: {
        logo: dashboard_customize,
        title: "數據分析 dashboard",
        url: "dashboard",
        items: {
          report: { url: "report", title: "銷售報表" },
        },
      },
      RBAC: {
        logo: admin_panel_settings,
        title: "RBAC 權限管理",
        url: "RBAC",
        items: {
          permissions: { url: "permissions", title: "權限設定" },
          character: { url: "character", title: "角色設定" },
        },
      },
    },
  },
  {
    type: "finance",
    list: {
      center: {
        logo: add_home,
        title: "帳務中心",
        url: "center",
        items: {
          payment: {
            url: "payment",
            title: "繳款紀錄查詢",
            describe: "查看所有營業項目之繳款紀錄",
            subItem: {
              detail: {
                url: "detail",
                title: "客戶詳情",
                describe: "查看客戶之繳款紀錄",
              },
            },
          },
          fileupload: {
            url: "fileupload",
            title: "繳款紀錄上傳",
            describe: "繳款紀錄上傳",
          },
          financeReview: {
            url: "financeReview",
            title: "財務查核表",
            describe: "查看客戶未結金額之紀錄。",
          },
        },
      },
    },
  },
  {
    type: "energy",
    list: {
      homepage: {
        logo: add_home,
        title: "首頁內容設定",
        url: "homepage",
        items: {
          calculator: {
            url: "calculator",
            title: "試算表設定",
            describe: "編輯試算表內容",
          },
          project: {
            url: "project",
            title: "案場列表",
            describe: "管理案場內容",
            subItem: {
              new: {
                url: "new",
                title: "新增案場",
                describe: "新增案場內容",
              },
            },
          },
        },
      },
    },
  },
  {
    type: "agritek",
    list: {
      pig: {
        logo: savings,
        title: "豬隻相關",
        url: "pig",
        items: {
          management: {
            url: "management",
            title: "豬豬管理總表",
            describe: "查看所有豬隻資料",
            subItem: {
              detail: {
                url: "detail",
                title: "豬豬管理總表",
                describe: "查看豬隻詳細資料",
              },
            },
          },
          mating: {
            url: "mating",
            title: "配種組別搜尋",
            describe: "搜尋配種組別",
          },
          farrow: {
            url: "farrow",
            title: "分娩組別搜尋",
            describe: "搜尋分娩組別",
          },
          search: {
            url: "search",
            title: "不能與配搜尋",
            describe: "搜尋不可交配的豬隻",
          },
          calculator: {
            url: "calculator",
            title: "豬豬近交計算",
            describe: "計算所有豬隻的近交係數",
          },
          setting: {
            url: "setting",
            title: "近交係數設定",
            describe: "設定近交係數值",
          },
        },
      },
    },
  },
];

export default sidebarConfig;
