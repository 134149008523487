import React from "react";
import ReactDOM from "react-dom/client";
import APP from "./App";
import "./index.css";

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <APP />
  </React.StrictMode>
);
